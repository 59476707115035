import React from "react";
import Header from "../Header/Header.jsx";
import "./Hero.css";
import hero_image from "../../assets/daipic.png";
import hero_image_back from "../../assets/hero_image_back.png";
import heart from "../../assets/heart.png";
import calories from "../../assets/calories.png";
import { motion } from "framer-motion";
import { Link } from "react-scroll";

import NumberCounter from "number-counter";

const Hero = () => {
	const transition = { type: "spring", duration: 3 };
	const mobile = window.innerWidth <= 700 ? true : false;
	return (
		<main className="hero">
			<div className="blur hero-blur"></div>
			<div className="left">
				<Header />

				<div className="the-ad">

					
					<motion.div
						initial={{ left: mobile ? "165 px" : "278px" }}
						whileInView={{ left: "9px" }}
						transition={{ ...transition, type: "tween" }}></motion.div>
					
					<span>Grind'On Strength & Fitness</span>
				</div>

				<p className="contact-info">+977 9863032963 | +977 9818950290</p>
				<p className="contact-info">Bhaktapur, Sallaghari</p>

				<div className="hero-text">
					<div>
						<span className="stroke-text">Shape </span>
						<span> Your</span>
					</div>

					<div>
						<span>Ideal body</span>
					</div>

					<div>
						<span>
							{/* Train whenever you like, 365 days a year – we’re here for you */}
							Happy new year offer! No registration fee for limited time
						</span>
					</div>
				</div>

				{/* <div className="figures">
					<div>
						<span>
							<NumberCounter end={140} start={100} delay="4" preFix="+" />
						</span>
						<span>expert coaches</span>
					</div>
					<div>
						<span>
							<NumberCounter end={978} start={800} delay="4" preFix="+" />
						</span>
						<span>members joined</span>
					</div>
					<div>
						<span>
							<NumberCounter end={50} start={0} delay="3" preFix="+" />
						</span>
						<span>fitness programs</span>
					</div>
				</div> */}

				<div className="hero-buttons">
					<a href="tel:+9779863032963" className="btn">Call Now</a>
					<Link to="plans" smooth={true} duration={700} className="btn">Pricings</Link>
				</div>
			</div>

			<div className="right">
				<a href="tel:+9779863032963" className="btn">CALL NOW</a>

				<motion.div
					initial={{ right: "-1rem" }}
					whileInView={{ right: "4rem" }}
					transition={transition}
					className="heart-rate">
					<img src={heart} alt="heart" />
					<span>Shape</span>
					<span>Your Body</span>
				</motion.div>

				<img src={hero_image} alt="heroimage" className="hero-image" />
				<motion.img
					initial={{ right: "11rem" }}
					whileInView={{ right: "20rem" }}
					transition={transition}
					src={hero_image_back}
					alt="heroimageback"
					className="hero-image-back"
				/>

				<motion.div
					initial={{ right: "37rem" }}
					whileInView={{ right: "28rem" }}
					transition={transition}
					className="calories">
					<img src={calories} alt="calories_image" />
					<div>
						<span>Uplift your</span>
						<span>Fitness</span>
					</div>
				</motion.div>
			</div>
		</main>
	);
};

export default Hero;
